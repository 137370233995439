import React, { useState, useEffect, useRef } from 'react';
import Background from './back.png'
class Eye {
  constructor(x, y, imageIndex) {
    this.position = { x, y };
    this.velocity = { 
      x: (Math.random() * 2 - 1) * 0.5,
      y: (Math.random() * 2 - 1) * 0.5
    };
    this.size = Math.random() * 0.7+ 0.3 ; // Scale factor between 0.7 and 1.0
    this.imageIndex = imageIndex;
    this.maxSpeed = 5;
    this.cursorThreshold = 200;
  }

  getSeparationDistance(otherEye) {
    // Using the average of both eyes' sizes for separation
    const avgSize = (this.size + otherEye.size) * 30; // Base size of 60px
    return avgSize + 40; // Additional margin
  }


  update(eyes, mousePos, bounds, avoidArea, imageSize) {
    const acceleration = { x: 0, y: 0 };
    
    // Repel from other eyes based on their sizes
    eyes.forEach(other => {
      if (other === this) return;
      
      const dx = this.position.x - other.position.x;
      const dy = this.position.y - other.position.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const minSeparation = this.getSeparationDistance(other);
      
      if (distance < minSeparation && distance > 0) {
        const force = Math.pow((minSeparation - distance) / minSeparation, 1.5);
        acceleration.x += (dx / distance) * force * 0.7;
        acceleration.y += (dy / distance) * force * 0.7;
      }
    });

    // Cursor interaction
    if (mousePos) {
      const dx = mousePos.x - this.position.x;
      const dy = mousePos.y - this.position.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      if (distance < this.cursorThreshold && distance > 0) {
        // Repel when closer than threshold
        const force = (this.cursorThreshold - distance) / distance;
        acceleration.x -= (dx / distance) * force * 0.4;
        acceleration.y -= (dy / distance) * force * 0.4;
      } else {
        // Attract when further than threshold
        const force = Math.min(1, (distance - this.cursorThreshold) / 200);
        acceleration.x += (dx / distance) * force * 0.15;
        acceleration.y += (dy / distance) * force * 0.15;
      }
    } else {
      // Wander behavior when no mouse
      acceleration.x += (Math.random() - 0.5) * 0.1;
      acceleration.y += (Math.random() - 0.5) * 0.1;
    }

    // Avoid title area
    if (avoidArea) {
      const margin = 60 * this.size;
      if (this.position.x > avoidArea.left - margin && 
          this.position.x < avoidArea.right + margin && 
          this.position.y > avoidArea.top - margin && 
          this.position.y < avoidArea.bottom + margin) {
        
        const centerX = (avoidArea.left + avoidArea.right) / 2;
        const centerY = (avoidArea.top + avoidArea.bottom) / 2;
        const dx = this.position.x - centerX;
        const dy = this.position.y - centerY;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        acceleration.x += (dx / distance) * 2;
        acceleration.y += (dy / distance) * 2;
      }
    }

    // Stay within bounds considering image size
    const margin = 60 * this.size;
    if (this.position.x < margin) acceleration.x += 0.5;
    if (this.position.x > bounds.width - margin) acceleration.x -= 0.5;
    if (this.position.y < margin) acceleration.y += 0.5;
    if (this.position.y > bounds.height - margin) acceleration.y -= 0.5;

    // Update velocity with inertia
    this.velocity.x = this.velocity.x * 0.95 + acceleration.x * 0.1;
    this.velocity.y = this.velocity.y * 0.95 + acceleration.y * 0.1;

    // Limit speed
    const speed = Math.sqrt(this.velocity.x ** 2 + this.velocity.y ** 2);
    if (speed > this.maxSpeed) {
      this.velocity.x = (this.velocity.x / speed) * this.maxSpeed;
      this.velocity.y = (this.velocity.y / speed) * this.maxSpeed;
    }

    // Update position
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;
  }
}

const InteractiveLanding = () => {
  const [mousePos, setMousePos] = useState(null);
  const [showTitle, setShowTitle] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const canvasRef = useRef(null);
  const eyesRef = useRef([]);
  const rafRef = useRef(null);
  const scrollRef = useRef(0);
  const [eyeImages, setEyeImages] = useState([]);

  // Load eye images
  useEffect(() => {
    setMousePos({ 
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    });
    const loadImages = async () => {
   
      const images = [];
      for (let i = 1; i <= 8; i++) {
        const img = new Image();
        img.src = `/eye${i}.png`; // Replace with your actual image paths
        await new Promise(resolve => {
          img.onload = resolve;
        });
        images.push(img);
      }
      setEyeImages(images);
    };
    loadImages();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const updateSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      
    };
    updateSize();
    window.addEventListener('resize', updateSize);

    // Initialize eyes
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    const avoidRadius = 150;
    const totaleyes = Math.ceil(window.innerWidth/30 + 50);
    eyesRef.current = Array.from({ length: totaleyes }, () => {
      let x, y;
      do {
        x = Math.random() * canvas.width;
        y = Math.random() * canvas.height;
      } while (
        Math.abs(x - centerX) < avoidRadius &&
        Math.abs(y - centerY) < avoidRadius
      );
      return new Eye(x, y, Math.floor(Math.random() * 8));
    });

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const animate = () => {
      if (!canvasRef.current || eyeImages.length === 0) return;
      
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Define central area to avoid
    
      const boxWidth = canvas.width > 700 ?  550 : 300;
      const boxHeight = canvas.height > 800 ?  550 : 400;
      const centerArea = {
        left: canvas.width / 2 - boxWidth / 2,
        right: canvas.width / 2 + boxWidth / 2,
        top: canvas.height / 2 - boxHeight / 2 - scrollRef.current,
        bottom: canvas.height / 2 + boxHeight / 2 - scrollRef.current
      };

      // Update and draw eyes
      eyesRef.current.forEach(eye => {
        eye.update(eyesRef.current, mousePos, { width: canvas.width, height: canvas.height }, centerArea);
        
        const img = eyeImages[eye.imageIndex];
        const dx = mousePos ? mousePos.x - eye.position.x : eye.velocity.x;
        const dy = mousePos ? mousePos.y - eye.position.y : eye.velocity.y;
        const angle = Math.atan2(dy, dx);
        
        ctx.save();
        ctx.translate(eye.position.x, eye.position.y);
        ctx.rotate(angle);
        
        // Draw the image with proper scaling
        const width = 60 * eye.size;
        const height = (60 * img.height / img.width) * eye.size;
        ctx.drawImage(img, -width/2, -height/2, width, height);
        
        ctx.restore();
      });

      rafRef.current = requestAnimationFrame(animate);
    };

    animate();
    return () => cancelAnimationFrame(rafRef.current);
  }, [mousePos, eyeImages]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePos({ x: e.clientX, y: e.clientY });

    };

    const handleMouseLeave = () => {
      setMousePos(null);
    };

    const handleScroll = () => {
      scrollRef.current = window.scrollY;
      if (window.scrollY > 100) {
        setShowEmail(true);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('scroll', handleScroll);

    setTimeout(() => setShowTitle(true), 2000);
    setTimeout(() => setShowSubtitle(true), 3000);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='min-h-screen bg-grey text-white overflow-hidden relative bg-[url("back.png")] bg-cover'>
      <canvas
        ref={canvasRef}
        className="fixed inset-0 z-0"
      />
      
      <div className="relative z-10">
        <div className="h-screen flex flex-col items-center justify-center">
          <div className="lg:w-[400px] lg:h-[450px] w-[300px] items-center	 bg-[#141414] bg-opacity-90  lg:p-14 md:p-8 rounded-lg">
            <h1 
              className={`text-5xl lg:mb-6 mb-4 text-center transition-opacity duration-1000 text-[40px] leading-[50px] lg:text-[50px] lg:leading-[60px]${
                showTitle ? 'opacity-100' : 'opacity-0'
              }`}
              style={{    fontFamily: "Montserrat", textAlign: 'left',
                fontWeight: '600' }}
            >
              The Behavioral Startup
            </h1>
            
            <h2 
              className={`text-xl text-center mx-auto transition-opacity duration-1000 text-[16px] leading-[24px] lg:text-[20px] lg:leading-[30px]${
                showSubtitle ? 'opacity-100' : 'opacity-0'
              }`}
              style={{ fontFamily: 'Montserrat, sans-serif', textAlign: 'left', fontWeight: '200' }}
            >
             A step-by-step method  <br/>
to turn your ideas <br/>
into successful products. <br/>

            </h2>
            <h3  className={`text-xl text-center mx-auto transition-opacity duration-1000 md:text-center ${
                showSubtitle ? 'opacity-100' : 'opacity-0'}`}
                style={{ marginTop:'60px', fontFamily: 'Lora, sans-serif', textAlign: 'left', fontSize: '12px', lineHeight: '30px', fontWeight: '200' }}
              > Nacho Parietti </h3>

          </div>
        </div>
        <div className="h-[200px]"></div>

        <div className={`min-h-screen flex items-center justify-center transition-opacity duration-1000 ${
          showEmail ? 'opacity-100' : 'opacity-0'
        }`}>
          <div className="text-center lg:w-[500px] md:w-[300px] bg-[#141414] bg-opacity-95  p-16 md:p-8 rounded-lg">
            <img 
              src="/BookCover.jpg" 
              alt="Book Cover Preview" 
              className="mx-auto mb-8 rounded-lg shadow-lg"
            />
            <div className="space-y-4 text-left mb-6">
              <h3 className="lg:text-xl md:text-md font-bold">What you'll get:</h3>
              <ul className="space-y-2">
                <li className="flex items-start space-x-2">
                  <svg className="w-5 h-5 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Early access to book chapters</span>
                </li>
                <li className="flex items-start space-x-2">
                  <svg className="w-5 h-5 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Exclusive behavioral design insights</span>
                </li>
                <li className="flex items-start space-x-2">
                  <svg className="w-5 h-5 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Case studies and practical examples</span>
                </li>
                <li className="flex items-start space-x-2">
                  <svg className="w-5 h-5 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Join our community of founders</span>
                </li>
              </ul>
            </div>
            
            
          </div>
        </div>
        <iframe 
              src="https://nachoparietti.substack.com/embed" 
              width="100%" 
              height="150" 
              style={{ border: '1px solid #EEE', background: 'white' }} 
              frameBorder="0" 
              scrolling="yes"
            ></iframe>
      </div>
      
    </div>
  );
};

export default InteractiveLanding;